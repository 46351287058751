<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="TBM 기본정보" class="cardClassDetailInfo">
            <template slot="card-button">
              <q-btn-group outline>
                <c-btn
                  v-show="disabled"
                  label="출력"
                  icon="print"
                  @btnClicked="printTbm"
                />
                <!-- <c-btn
                  v-show="editable && tabParam.tbmId && !disabled"
                  :url="completeAllUrl"
                  :isSubmit="isAllComplete"
                  :param="tabParam"
                  mappingType="PUT"
                  label="TBM 완료"
                  icon="check"
                  @beforeAction="completeTbm"
                  @btnCallback="completeAllCallback"
                />
                <c-btn
                  v-show="editable && tabParam.tbmId && !disabled"
                  label="TBM 삭제"
                  :editable="editable"
                  icon="delete_forever"
                  @btnClicked="remove"
                /> -->
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-label-text title="TBM명" :value="tabParam.tbmName"></c-label-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-label-text title="공정" :value="tabParam.processName"></c-label-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-label-text title="작업" :value="tabParam.sopName"></c-label-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-label-text title="작업일" :value="tabParam.tbmWorkDate"></c-label-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="equipTable"
            title="관련 유해위험기계기구 목록"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="tabParam.tbmHazardEquipModelList"
            :filtering="false"
            :columnSetting="false"
            :checkClickFlag="false"
            :usePaging="false"
            :isExcelDown="false"
            :hideBottom="true"
            :editable="editable&&!disabled"
            rowKey="tbmHazardEquipId"
            selection="multiple"
            @linkClick="linkClick"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn v-if="editable && !disabled" :showLoading="false" label="추가" icon="add" @btnClicked="selectEquip" />
                <c-btn v-if="editable && !disabled && tabParam.tbmHazardEquipModelList.length > 0" :showLoading="false"  label="삭제" icon="remove" @btnClicked="removeEquip" />
                <c-btn
                  v-show="false"
                  :url="equipInserUrl"
                  :isSubmit="isSave"
                  :param="tabParam"
                  mappingType="POST"
                  label="저장"
                  icon="save"
                  @beforeAction="saveEquip"
                  @btnCallback="saveCallback"
                />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props }">
              <template v-if="props.row['inspectionSubjectFlag']">
                <q-chip
                  :color="setTagColor(props.row.inspectionSubjectFlag)"
                  :class="props.row.inspectionSubjectFlag ==='A' ? 'blinking' : null"
                  outline square
                  text-color="white"
                  >
                  <q-avatar v-if="props.row.inspectionSubjectFlag==='A'" icon="alarm" color="red" text-color="white" />
                  {{setTagName(props.row.inspectionSubjectFlag)}}
                </q-chip>
              </template>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
export default {
  name: 'tbm-relation-machinery',
  props: {
    tabParam: {
      type: Object,
      default(){
        return {
          tbmCompleteFlag: 'N',
          sopWorkPermitId: "",
          tbmHazardEquipModelList: [],
          deleteTbmHazardEquipModelList: [],
          tbmId: "",
        }
      },
    },
  },
  data() {
    return {
      getUrl: '',
      grid: {
        columns: [
          {
            name: "machineryName",
            field: "machineryName",
            label: "기계명",
            style: 'width:200px', 
            align: "left",
            type: 'link',
            sortable: true,
          },
          {
            name: "inspectionSubjectFlag",
            field: "inspectionSubjectFlag",
            label: "검사상태",
            style: 'width:100px', 
            type: 'custom',
            align: "center",
            sortable: true,
          },
          {
            name: "itemNo",
            field: "itemNo",
            label: "item 번호",
            style: 'width:150px', 
            align: "center",
            sortable: true,
          },
          {
            name: "machineryClassificationName",
            field: "machineryClassificationName",
            label: "기계분류",
            style: 'width:80px', 
            align: "center",
            sortable: true,
          },
          {
            name: "resultCd",
            field: "resultCd",
            label: "검사결과",
            style: 'width:100px', 
            type: 'tag',
            colorItems: [
              { stepperMstCd: 'HRC000005', stepperMstNm: '합격', colorClass: 'blue' },
              { stepperMstCd: 'HRC000010', stepperMstNm: '불합격', colorClass: 'red' },
            ],
            align: "center",
            sortable: true,
          },
          {
            name: "gradeName",
            field: "gradeName",
            label: "등급",
            align: "center",
            style: 'width:50px', 
            sortable: true,
          },
        ],
        height: '700px',
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'typeName',
            field: 'typeName',
            label: '구분',
            style: 'width:20%',
            align: 'center',
            sortable: false,
          },
          {
            name: 'resultName',
            field: 'resultName',
            label: '점검항목',
            style: 'width:50%',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            setHeader: true,
            name: 'checkResult',
            field: 'checkResult',
            label: '점검결과',
            style: 'width:30%',
            type: 'textarea',
            align: 'left',
            sortable: false,
          },
        ],
        height: '400px',
        data: [],
      },
      grid3: {
        columns: [
          {
            name: 'riskFactors',
            field: 'riskFactors',
            label: '위험요인',
            align: 'left',
            style: 'width:30%',
            sortable: false
          },
          {
            name: 'safetyMeasure',
            field: 'safetyMeasure',
            label: '개선대책',
            align: 'left',
            style: 'width:70%',
            sortable: false,
          },
        ],
        data: [],
        height: '270px'
      },
      result: {
        tbmId: '',
        tbmHazardEquipId: '',
        saiContructFacilityId: '',
        facilityTypeCd: '',
        facilityTypeName: '',
        facilityName: '',
        preInspectionFlag: 'N',

        tbmHazardEquipResultModelList: [],
        deleteTbmHazardEquipResultModelList: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        // isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      colorItems: [
        { stepperMstCd: 'Y', stepperMstNm: '대상', colorClass: 'green' },
        { stepperMstCd: 'N', stepperMstNm: '비대상', colorClass: 'orange' },
        { stepperMstCd: 'A', stepperMstNm: '지연', colorClass: 'red' },
      ],
      isSave: false,
      isComplete: false,
      isAllComplete: false,
      editable: false,
      equipInserUrl: "transactionConfig.sai.tbm.insert.equip.url",
      completeUrl: "transactionConfig.sai.tbm.preComplete.url",
      equipDeleteUrl: "transactionConfig.sai.tbm.delete.equip.url",
      isResultSave: false,
      deleteUrl: '',
      completeAllUrl: 'transactionConfig.sai.tbm.insert.url',
      
      equipResultSelectUrl: "selectConfig.sai.tbm.insert.equip.result.url",
      equipResultInsertUrl: "transactionConfig.sai.tbm.get.equip.result.url"
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
    disabled() {
      return this.tabParam.tbmCompleteFlag === 'Y'
    },
    isWorkPermit() {
      return (
        Boolean(this.tabParam.sopWorkPermitId) &&
        this.editable &&
        this.tabParam.tbmCompleteFlag === "N"
      );
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.completeAllUrl = transactionConfig.sai.tbm.complete.url;
      this.deleteUrl = transactionConfig.sai.tbm.delete.url;

      this.completeUrl = transactionConfig.sai.tbm.preComplete.url;
      this.equipInserUrl = transactionConfig.sai.tbm.insert.equip.url;
      this.equipDeleteUrl = transactionConfig.sai.tbm.delete.equip.url
      
      this.equipResultSelectUrl = selectConfig.sai.tbm.get.equip.result.url;
      this.equipResultInsertUrl = transactionConfig.sai.tbm.insert.equip.result.url;
    },
    selectEquip() {
      this.popupOptions.title = '기계/장비 검색'; // 기계/장비 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/mim/hazardEquipment/hazardEquipmentClassPop.vue'}`);
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipPopup;
    },
    closeEquipPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let index = this.$_.findIndex(this.tabParam.tbmHazardEquipModelList, {
            saiContructFacilityId: item.hhmHazardousMachineryId
          })
          if (index === -1) {
            this.tabParam.tbmHazardEquipModelList.push({
              tbmId: this.tabParam.tbmId,
              tbmHazardEquipId: uid(),
              saiContructFacilityId: item.hhmHazardousMachineryId,
              emplacementPlace: item.emplacementPlace,
              plantName: item.plantName,
              machineryClassification: item.machineryClassification,
              machineryName: item.machineryName,
              volume: item.volume,
              editFlag: 'C'
            })
          }
        })
        this.isSave = !this.isSave;
      }
    },
    // _result
    rowClick() {
      window.getApp.$emit('ALERT', {
        title: '안내',
        message: '개발중입니다.', // 개발중입니다
        type: 'warning', // success / info / warning / error
      });
      return;
      
      // this.result.tbmId = this.tabParam.tbmId;
      // this.result.tbmHazardEquipId = _result.tbmHazardEquipId;
      // this.result.saiContructFacilityId = _result.saiContructFacilityId;
      // this.result.facilityTypeCd = _result.facilityTypeCd;
      // this.result.facilityTypeName = _result.facilityTypeName;
      // this.result.facilityName = _result.facilityName;
      // this.result.preInspectionFlag = _result.preInspectionFlag ? _result.preInspectionFlag : 'N';

      // this.resultItem(_result) // 점검항목 목록
      // this.safetyMeasure(_result) // 안전대책 목록
    },
    resultItem(_result) {
      if (!_result) return;
      this.$http.url = this.$format(this.equipResultSelectUrl, _result.tbmHazardEquipId)
      this.$http.type = 'GET';
      this.$http.request((result) => {
        if (result.data && result.data.length > 0) {
          this.result.tbmHazardEquipResultModelList = this.$_.clone(result.data)
        } else {
          this.$http.url = selectConfig.pjm.inspectitem.list.url
          this.$http.type = 'GET';
          this.$http.param = {hhmTypeCd: _result.facilityTypeCd};
          this.$http.request((_result) => {
            if (_result.data) {
              this.result.tbmHazardEquipResultModelList = [];
              this.$_.forEach(_result.data, _item => {
                this.result.tbmHazardEquipResultModelList.push({
                  tbmId: this.tabParam.tbmId,
                  tbmHazardEquipId: _result.tbmHazardEquipId,
                  tbmHazardEquipResultId: uid(),
                  typeName: _item.typeName,
                  resultName: _item.inspectItem,
                  checkValue: 'Y',
                  safetyMeasure: '',
                  editFlag: 'C'
                })
              })
            }
          },);
        }
      },);
    },
    safetyMeasure(_result) {
      this.$http.url = this.$format(selectConfig.sai.machinery.get.url, _result.saiContructFacilityId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid3.data = this.$_.clone(_result.data.machinerySafetyMeasureModelList);
      },);
    },
    saveEquip() {
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
            window.getApp.$emit("CONFIRM", {
            title: "확인",
            message: "저장하시겠습니까?",
            
            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.tabParam.regUserId = this.$store.getters.user.userId;
              this.tabParam.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    saveEquipResult() {
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
            window.getApp.$emit("CONFIRM", {
            title: "확인",
            message: "저장하시겠습니까?",
            
            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.tabParam.regUserId = this.$store.getters.user.userId;
              this.tabParam.chgUserId = this.$store.getters.user.userId;
              this.isResultSave = !this.isResultSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    preComplete() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '사전점검완료 하시겠습니까?',
        // TODO : 점검완료 하시겠습니까?
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.isComplete = !this.isComplete;
        },
        // 취소 callback 함수
        cancelCallback: () => {
      },
    });
    },
    completeTbm() {
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
          window.getApp.$emit("CONFIRM", {
            title: "확인",
            message: "TBM을 완료하시겠습니까? 완료할 시 탭[ TBM 기본정보, 참석자 및 건강상태, 관련 기계 및 장비 ]을 수정 할 수 없습니다.",
            
            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.tabParam.regUserId = this.$store.getters.user.userId;
              this.tabParam.chgUserId = this.$store.getters.user.userId;
              this.isAllComplete = !this.isAllComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    completeAllCallback() {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.$emit("getDetail");
    },
    saveCallback() {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.$emit("getDetail")
    },
    saveResultCallback() {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
    },
    completeCallback() {
    this.result.disable = true;
    this.result.preInspectionFlag = 'Y'

    this.rowClick(this.result);
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.$emit("getDetail");
    },
    removeEquip() {
      let selectData = this.$refs['equipTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.equipDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.tabParam.tbmHazardEquipModelList = this.$_.reject(this.tabParam.tbmHazardEquipModelList, { tbmHazardEquipId: item.tbmHazardEquipId })
              })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.result.tbmHazardEquipResultModelList = [];
              this.grid3.data = [];
            },);
          },
          cancelCallback: () => {
          },
        });
      }
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: 'TBM을 삭제하시겠습니까? 삭제할 시 모든 정보가 삭제됩니다.',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.tabParam.tbmId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    printTbm() {
      let thisVue = this;
      this.$http.url = this.$format(selectConfig.sai.tbm.print.url, this.tabParam.tbmId);
      this.$http.type = "GET";
      this.$http.request(
        (_result) => {
          let fileOrgNm = this.tabParam.tbmNo + ".xlsx";
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        },
        () => {}
      );
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "유해위험기계기구 상세"; // 유해위험기계기구 상세
      this.popupOptions.param = {
        hhmHazardousMachineryId: row.saiContructFacilityId ? row.saiContructFacilityId : '',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/mim/hazardEquipment/hazardEquipmentClassDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.height = '300px';
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>