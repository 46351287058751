import { render, staticRenderFns } from "./tbmRelationMachinery.vue?vue&type=template&id=61970b3b"
import script from "./tbmRelationMachinery.vue?vue&type=script&lang=js"
export * from "./tbmRelationMachinery.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\psm\\PSMFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61970b3b')) {
      api.createRecord('61970b3b', component.options)
    } else {
      api.reload('61970b3b', component.options)
    }
    module.hot.accept("./tbmRelationMachinery.vue?vue&type=template&id=61970b3b", function () {
      api.rerender('61970b3b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/sai/tbm/tbmRelationMachinery.vue"
export default component.exports